import React from "react"
import Layout from '../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import pageStyles from '../templates/page.module.scss'

const BlogPage = ({data}) => {
    const posts = data.allContentfulBlogPost.edges
    return (
        <Layout>
            <div className="blog-list">        
            {posts.map(({node: blogPost}) => {
                const { publishDate, slug, title, excerpt, id } = blogPost
                const postDate = new Date(publishDate)
                return (<div className={pageStyles.blogExcerpt}>
                    <Link to={`/blog/${slug}`} key={id}>
                        <h4 className={pageStyles.title}>{title}</h4>
                    </Link>
                    <p className={pageStyles.publishDate}>Published {postDate.toDateString()} at {(postDate.getHours() % 12 === 0 ? 12 : postDate.getHours() % 12)}:{(postDate.getMinutes())} {postDate.getHours >= 12 ? 'pm' : 'am'}</p>
                    <p>{excerpt}</p>
                </div>)
            })}
            </div>
        </Layout>
    );
}

export default BlogPage;
export const query = graphql`
    query {
        allContentfulBlogPost {
            edges {
                node {
                    id
                    publishDate
                    excerpt
                    title
                    slug
                    updatedAt
                }
            }
        }
    }
`
